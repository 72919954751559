import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { MdHomeFilled, MdPhone, MdSportsHockey } from "react-icons/md"
import { FaClipboardCheck } from "react-icons/fa"

import SportsLogo from "../components/sportsLogo"

const Navbar = () => {
  // Enter page details here, for inclusion in nav bar

  const pages = {
    home: ["Home", "/", <MdHomeFilled />],
    registration: ["Registration", "/registration", <FaClipboardCheck />],
    divisions: ["Divisions", "/divisions", <MdSportsHockey />],
    contact: ["Contact", "/contact", <MdPhone />],
  }
  // create array of the object keys to .map over the pages object
  // reference: https://dev.to/stu/how-to-iterate-over-objects-using-array-methods-662
  const pageKeyArray = Object.keys(pages)

  return (
    <div className="  w-full flex  items-center z-20  bg-primary  sticky top-0 left-0 right-0 ">
      <Link title="Home" className=" ml-2" to="/">
        <SportsLogo className="" alt="SLMHA Logo" />
      </Link>
      <nav className="navbar__topLinks flex-grow text-3xl md:text-4xl">
        {pageKeyArray.map((key, index) => (
          <Link
            activeClassName="navbar__activeLink"
            className="navbar__link"
            to={`${pages[key][1]}`}
            key={index}
          >
            <span className="navbar__linkText">{pages[key][0]}</span>
            {/* <span className="navbar__svg" title={pages[key][0]}>
              {pages[key][2]}
            </span> */}
          </Link>
        ))}
        <div className="navbar__menu" title="More Options"></div>
      </nav>
    </div>
  )
}

Navbar.propTypes = {
  siteTitle: PropTypes.string,
}

Navbar.defaultProps = {
  siteTitle: ``,
}

export default Navbar
