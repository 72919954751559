import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const SportsLogo = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        logoImage: file(relativePath: { eq: "SLMHA_4x.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <div className="w-20">
        <Img
          className={className}
          fluid={data.logoImage.childImageSharp.fluid}
          alt="Sioux Lookout Minor Hockey Association Logo"
        />
      </div>
    )}
  />
)

export default SportsLogo
