/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Footer from "./footer"
import Navbar from "./navbar"
import "normalize.css/normalize.css"
import "../styles/components/layout.scss"
import HamburgerMenu from "./hamburgerMenu"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={data => {
      return (
        <div className="relative ">
          <HamburgerMenu />
          <div className="relative flex flex-col min-h-screen items-center w-full ">
            <Navbar />
            <main className="   flex-grow flex flex-col ">
              <div className="flex-grow  mb-5 grid justify-items-stretch ">
                {children}
              </div>
            </main>
            <Footer />
          </div>
        </div>
      )
    }}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
